import { defineNuxtRouteMiddleware } from '#imports';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $i18n } = useNuxtApp();
  const { $supabase } = useNuxtApp();
  const session = await $supabase.auth.getSession();

  if (!session.data.session?.user.id) {
    const defaultLanguage = useRequestHeader('accept-language')?.slice(0,2);
    $i18n.setLocale(defaultLanguage || 'en');
    return;
  }

  const userLang = await $supabase.from('users').select('language').eq('id', session.data.session?.user.id ?? '').single();

  const defaultLanguage = useRequestHeader('accept-language')?.slice(0,2);
  $i18n.setLocale(userLang.data?.language || defaultLanguage || 'en');
});
